/* ================================== 0 ================================== */
.m-0 {
	margin: 0;
}

.mr-0 {
	margin-right: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.ml-0 {
	margin-left: 0;
}

.mtb-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mlr-0 {
	margin-left: 0;
	margin-right: 0;
}



/* ================================== 8 ================================== */
.m-8 {
	margin: 8px;
}

.mr-8 {
	margin-right: 8px;
}

.mb-8 {
	margin-bottom: 8px;
}

.ml-8 {
	margin-left: 8px;
}

.mtb-8 {
	margin-top: 8px;
	margin-bottom: 8px;
}

.mlr-8 {
	margin-left: 8px;
	margin-right: 8px;
}



/* ================================== 16 ================================== */
.m-16 {
	margin: 16px;
}

.mr-16 {
	margin-right: 16px;
}

.mb-16 {
	margin-bottom: 16px;
}

.ml-16 {
	margin-left: 16px;
}

.mtb-16 {
	margin-top: 16px;
	margin-bottom: 16px;
}

.mlr-16 {
	margin-left: 16px;
	margin-right: 16px;
}



/* ================================== 24 ================================== */
.m-24 {
	margin: 24px;
}

.mr-24 {
	margin-right: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.ml-24 {
	margin-left: 24px;
}

.mtb-24 {
	margin-top: 24px;
	margin-bottom: 24px;
}

.mlr-24 {
	margin-left: 24px;
	margin-right: 24px;
}
