/* ================================== WIDTH ================================== */
.full-viewport-width {
	width: 100vw;
}

.full-width {
	width: 100%;
}

.min-full-viewport-width {
	min-width: 100vw;
}

.min-full-width {
	min-width: 100%;
}



/* ================================== HEIGHT ================================== */
.full-viewport-height {
	height: 100vh;
}

.full-height {
	height: 100%;
}

.min-full-viewport-height {
	min-height: 100vh;
}

.min-full-height {
	min-height: 100%;
}



/* ================================== WIDTH - HEIGHT ================================== */
.full-width-height {
	width: 100%;
	height: 100%;
}

.full-viewport-width-height {
	width: 100vw;
	height: 100vh;
}
