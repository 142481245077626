/* ========================================= GENERAL ========================================= */
.fade-up-in.slow-animation {
	animation-duration: .6s;
}



/* ========================================= ENTER DOM CLASSES ========================================= */
.fade-up-in {
	animation: fadeUpIn .3s ease-out forwards;
	will-change: opacity, transform;
}



/* ========================================= ANIMATIONS ========================================= */
@keyframes fadeUpIn {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
