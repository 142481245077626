/* ============================== NEUTRAL ============================== */
.transparent-background {
	background-color: transparent;
}

.white-background {
	background-color: #FFF;
}

.opaque-white-background {
	background-color: var(--opaque-white-background-color);
}

.gray-background {
	background-color: #EEE;
}

.dark-gray-background {
	background-color: #999;
}

.light-gray-background {
	background-color: #F4F4F4;
}



/* ============================== PRIMARY ============================== */
.primary-background {
	background-color: var(--primary-color);
}

.primary-dark-background {
	background-color: var(--primary-color-dark);
}

.primary-darkest-background {
	background-color: var(--primary-color-darkest);
}

.primary-darkest-background-imp {
	background-color: var(--primary-color-darkest) !important;
}

.horizontal-primary-gradiant-background {
	background: linear-gradient(to right, #455a64, #576c76, #6a7e88, #7d919b, #90a4ae);
}



/* ============================== SECONDARY ============================== */
.horizontal-secondary-gradiant-background {
	background: linear-gradient(to right, #e64a19, #ed5b2e, #f46b41, #fa7b53, #ff8a65);
}



/* ============================== PRIMARY / SECONDARY ============================== */
.diagonal-colors-gradiant-background {
	background: linear-gradient(to left top, #af81b8, #cf7daf, #e97b9c, #fa7f82, #ff8a65);
}

.horizontal-colors-gradiant-background {
	background: linear-gradient(to right, #af81b8, #cf7daf, #e97b9c, #fa7f82, #ff8a65);
}
