/* ================================== 0 ================================== */
.p-0 {
	padding: 0;
}

.pt-0 {
	padding-top: 0;
}

.pr-0 {
	padding-right: 0;
}

.pb-0 {
	padding-bottom: 0;
}

.pl-0 {
	padding-left: 0;
}

.ptb-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.plr-0 {
	padding-left: 0;
	padding-right: 0;
}



/* ================================== 4 ================================== */
.p-4 {
	padding: 4px;
}

.pt-4 {
	padding-top: 4px;
}

.pr-4 {
	padding-right: 4px;
}

.pb-4 {
	padding-bottom: 4px;
}

.pl-4 {
	padding-left: 4px;
}

.ptb-4 {
	padding-top: 4px;
	padding-bottom: 4px;
}

.plr-4 {
	padding-left: 4px;
	padding-right: 4px;
}



/* ================================== 8 ================================== */
.p-8 {
	padding: 8px;
}

.pt-8 {
	padding-top: 8px;
}

.pr-8 {
	padding-right: 8px;
}

.pb-8 {
	padding-bottom: 8px;
}

.pl-8 {
	padding-left: 8px;
}

.ptb-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.plr-8 {
	padding-left: 8px;
	padding-right: 8px;
}



/* ================================== 16 ================================== */
.p-16 {
	padding: 16px;
}

.pt-16 {
	padding-top: 16px;
}

.pr-16 {
	padding-right: 16px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pl-16 {
	padding-left: 16px;
}

.ptb-16 {
	padding-top: 16px;
	padding-bottom: 16px;
}

.plr-16 {
	padding-left: 16px;
	padding-right: 16px;
}



/* ================================== 24 ================================== */
.p-24 {
	padding: 24px;
}

.pt-24 {
	padding-top: 24px;
}

.pr-24 {
	padding-right: 24px;
}

.pb-24 {
	padding-bottom: 24px;
}

.pl-24 {
	padding-left: 24px;
}

.ptb-24 {
	padding-top: 24px;
	padding-bottom: 24px;
}

.plr-24 {
	padding-left: 24px;
	padding-right: 24px;
}
