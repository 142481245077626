/* === FADE === */
.fade-in-fast {
	visibility: hidden;
	opacity: 0;
	will-change: visibility, opacity;
}

.fade-in-fast.show {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s, opacity .2s ease-out;
}
