/* ================================== DEBUG ================================== */
.red-border {
	box-shadow: inset 1px 1px 0 red, inset -1px -1px 0 red;
}

.green-border {
	box-shadow: inset 1px 1px 0 green, inset -1px -1px 0 green;
}

.blue-border {
	box-shadow: inset 1px 1px 0 blue, inset -1px -1px 0 blue;
}

.pink-border {
	box-shadow: inset 1px 1px 0 deeppink, inset -1px -1px 0 deeppink;
}



/* ================================== RESET ================================== */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;

	text-decoration: none;
	color: unset;


	/* ---------- GENERAL COLORS ---------- */
	--opaque-white-background-color: rgba(255, 255, 255, .8);


	/* ---------- PRIMARY COLOR ---------- */
	/* === background === */
	--primary-color-light: #90a4ae;

	--primary-color: #607d8b;

	--primary-color-dark: #455a64;
	--primary-color-darkest: #263238;

	/* === text === */
	--primary-text-color: #FFF;


	/* ---------- SECONDARY COLOR ---------- */
	/* === background === */
	--secondary-color-light: #ff8a65;

	--secondary-color: #ff5722;

	--secondary-color-dark: #e64a19;
	--secondary-color-darkest: #bf360c;

	/* === text === */
	--secondary-text-color: #FFF;
}



/* ================================== DEFAULT STYLES ================================== */
html,
body {
	min-height: 100vh;
}

html {
	background-color: var(--primary-color-darkest);
}

body {
	background-color: var(--primary-color-dark);
}



/* ================================== SCROLL ================================== */
/* =============== SCROLL BAR =============== */
.MuiDialogContent-root::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0, 0, 0, .1);
	border: 1px solid #CCC;
}

.MuiDialogContent-root::-webkit-scrollbar {
	background-color: #FFF;
	width: 8px;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, .3);
	border: 1px solid #AAA;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
	background: #FFF;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb:active {
	background: linear-gradient(to right, #22ADD4, #1E98BA);
}
