/* ==================================== OVERLAY ==================================== */
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.fixed-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

.loader-overlay {
	background-color: var(--opaque-white-background-color);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s ease-out .6s, opacity .2s ease-out .4s;
}

.loader-overlay.loading {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s ease-out, opacity .2s ease-out .4s;
}



/* ==================================== ICON BUTTON ==================================== */
.icon-button-clone {
	width: 48px;
	height: 48px;
}



/* ==================================== MATERIAL-UI BUTTON ==================================== */
.MuiFormControl-root .Mui-disabled {
	opacity: .4;
}


/* ==================================== MATERIAL-UI BUTTON ==================================== */
/* === PRIMARY === */
.MuiButton-root.primary-button {
	background-image: linear-gradient(to right, #455a64, #576c76, #6a7e88, #7d919b, #90a4ae);
	color: #FFF;
}

.MuiButton-root.primary-button,
.MuiButton-root.primary-button.Mui-disabled {
	border: none;
}


/* === SECONDARY === */
.MuiButton-root.secondary-button {
	background-image: linear-gradient(to right, #e64a19, #ed5b2e, #f46b41, #fa7b53, #ff8a65);
	color: #FFF;
}

.MuiButton-root.secondary-button,
.MuiButton-root.secondary-button.Mui-disabled {
	border: none;
}


/* === ERROR === */
.MuiButton-root.error-button {
	background-image: linear-gradient(to right, #993129, #b24238, #cb5249, #e5645a, #ff756b);
	color: #FFF;
}

.MuiButton-root.error-button,
.MuiButton-root.error-button.Mui-disabled {
	border: none;
}
