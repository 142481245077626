.overflow-hidden {
	overflow: hidden;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.overflow-y-hidden {
	overflow-y: hidden;
}

.overflow-x-auto {
	overflow-x: auto;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.overflow-y-scroll {
	overflow-y: scroll;
}



/* =============== SCROLL BAR =============== */
.overflow-y-auto::-webkit-scrollbar-track,
.overflow-y-scroll::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0, 0, 0, .1);
	border: 1px solid #CCC;
}

.overflow-y-auto::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar {
	background-color: #FFF;
	width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-thumb,
.overflow-y-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	/*background: linear-gradient(left, #FFF, #E4E4E4);*/
	background: rgba(0, 0, 0, .3);
	border: 1px solid #AAA;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover,
.overflow-y-scroll::-webkit-scrollbar-thumb:hover {
	background: #FFF;
}

.overflow-y-auto::-webkit-scrollbar-thumb:active,
.overflow-y-scroll::-webkit-scrollbar-thumb:active {
	background: linear-gradient(to right, #22ADD4, #1E98BA);
}
