.PainlessPasswordIcon .cursor {
	visibility: hidden;
}



/* ===================== ANIMATED ===================== */
.PainlessPasswordIcon.animate .asterisk {
	fill: #FFF;
}

/* === cursor === */
.PainlessPasswordIcon.animate .cursor {
	visibility: visible;
	transform: translateX(-61.4%);
	animation: cursorMove 5s linear 1s infinite normal;
}

/* === 1 asterisk === */
.PainlessPasswordIcon.animate .n-1 {
	animation: asteriskAppear 5s linear 0s infinite normal;
}

/* === 2 asterisk === */
.PainlessPasswordIcon.animate .n-2 {
	animation: asteriskAppear 5s linear .3s infinite normal;
}

/* === 3 asterisk === */
.PainlessPasswordIcon.animate .n-3 {
	animation: asteriskAppear 5s linear .6s infinite normal;
}

/* === 4 asterisk === */
.PainlessPasswordIcon.animate .n-4 {
	animation: asteriskAppear 5s linear .9s infinite normal;
}

/* === 5 asterisk === */
.PainlessPasswordIcon.animate .n-5 {
	animation: asteriskAppear 5s linear 1.2s infinite normal;
}

/* === 6 asterisk === */
.PainlessPasswordIcon.animate .n-6 {
	animation: asteriskAppear 5s linear 1.5s infinite normal;
}

@keyframes asteriskAppear {
	0% {
		fill: #FFF;
	}
	49% {
		fill: #FFF;
	}
	50% {
		fill: #607D8B;
	}
	80% {
		fill: #607D8B;
	}
	81% {
		fill: #FFF;
	}
	100% {
		fill: #FFF;
	}
}

@keyframes cursorMove {
	0% {
		transform: translateX(-61.4%);
	}


	27% {
		transform: translateX(-61.4%);
	}
	28% {
		transform: translateX(-51.4%);
	}


	32% {
		transform: translateX(-51.4%);
	}
	33% {
		transform: translateX(-41%);
	}


	40% {
		transform: translateX(-41%);
	}
	41% {
		transform: translateX(-30.6%);
	}


	46% {
		transform: translateX(-30.6%);
	}
	47% {
		transform: translateX(-20.2%);
	}


	53% {
		transform: translateX(-20.2%);
	}
	54% {
		transform: translateX(-9.8%);
	}


	57% {
		transform: translateX(-9.8%);
	}
	58% {
		transform: translateX(0%);
	}


	94% {
		transform: translateX(0%);
	}
	98% {
		transform: translateX(-61.4%);
	}
	100% {
		transform: translateX(-61.4%);
	}
}
