/* ================================== TEXT TRANSFORM ================================== */
.caps {
	text-transform: uppercase;
}



/* ================================== FONT WEIGHT ================================== */
.font-weight-300 {
	font-weight: 300;
}

.font-weight-400 {
	font-weight: 400;
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-700 {
	font-weight: 700;
}



/* ================================== ALIGN ================================== */
.text-center {
	text-align: center;
}



/* ================================== STYLE ================================== */
.italic {
	font-style: italic;
}



/* ================================== COLOR ================================== */
.white-text {
	color: #FFF;
}

.gray-text {
	color: #EEE;
	fill: #EEE;
}

.primary-text {
	color: var(--primary-color);
	fill: var(--primary-color);
}

.primary-darker-text {
	color: var(--primary-color-dark);
	fill: var(--primary-color-dark);
}

.primary-darkest-text {
	color: var(--primary-color-darkest);
	fill: var(--primary-color-darkest);
}

.disabled-text {
	color: #999;
}

.primary-gradiant-text {
	background-image: linear-gradient(to right, #455a64, #576c76, #6a7e88, #7d919b, #90a4ae);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.secondary-gradiant-text {
	background-image: linear-gradient(to right, #e64a19, #ed5b2e, #f46b41, #fa7b53, #ff8a65);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.colors-gradiant-text {
	background-image: linear-gradient(to right, #af81b8, #cf7daf, #e97b9c, #fa7f82, #ff8a65);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}



/* ================================== MISC ================================== */
.break-anywhere {
	overflow-wrap: anywhere;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
