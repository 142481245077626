.relative {
	position: relative;
}

.fixed {
	position: fixed;
}

.absolute {
	position: absolute;
}

.top {
	top: 0;
}

.right {
	right: 0;
}

.bottom {
	bottom: 0;
}

.left {
	left: 0;
}

.top-left {
	top: 0;
	left: 0;
}

.bottom-right {
	bottom: 0;
	right: 0;
}
